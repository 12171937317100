<template>
  <el-select :disabled="disabled || isLoading" :multiple="multiple" :value="value" @input="onInput" filterable>
    <el-option
      v-if="nullable"
      :label="typeof nullable === 'string' ? nullable : '(No creator)'"
      :value="null"
    />
    <el-option v-for="creator in creators" :key="creator.id" :label="creator.name" :value="creator.id"></el-option>
  </el-select>
</template>

<script>
import { isEqual } from "lodash";

export default {
  name: "apa-task-creator-select",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Object
    },
    multiple: {
      type: Boolean
    },
    nullable: {
      type: [Boolean, String]
    },
    value: {
      type: [Array, Number]
    },
    project: {
      type: [Number]
    }
  },
  data() {
    return {
      isLoading: true,
      creators: []
    };
  },
  watch: {
    filter(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.load();
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load: async function() {
      this.isLoading = true;
      let project_query = '';
      if (this.project) {
        project_query = {
            and: {
              project_id: this.project
            }
          }
      }
      const task_users = await this.$store.dispatch("findUsers", {
        data: {
          and: this.filter,
          with: {
            creator: {
              one: 'task',
              this: 'id',
              that: 'created_by',
              query: project_query
            }
          }
        },
        options: {
          commit: false
        }
      }
    );

      this.isLoading = false;
      
      this.creators = task_users.filter((u) => u.creator !== null && u.role_id != 18).sort((left, right) =>
        left.name.localeCompare(right.name)
      )
      
    },
    onInput(value) {
      this.$emit("input", value);
    }
  }
};
</script>
