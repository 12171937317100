<template>
  <el-select :disabled="disabled || isLoading" :multiple="multiple" :value="value" @input="onInput" filterable>
    <el-option
      v-if="nullable"
      :label="typeof nullable === 'string' ? nullable : '(No team)'"
      :value="null"
    />
    <el-option v-for="team in teams" :key="team.id" :label="team.name" :value="team.user_ids"></el-option>
  </el-select>
</template>

<script>
import { isEqual } from "lodash";

export default {
  name: "apa-team-select",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Object
    },
    multiple: {
      type: Boolean
    },
    nullable: {
      type: [Boolean, String]
    },
    value: {
      type: [Array, Number]
    },
    project: {
      type: [Number]
    }
  },
  data() {
    return {
      isLoading: true,
      teams: []
    };
  },
  watch: {
    filter(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.load();
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load: async function() {
      this.isLoading = true;
      let project_query = '';
      if (this.project) {
        project_query = {
            and: {
              project_id: this.project
            }
          }
      }
      const task_teams = await this.$store.dispatch("findTask_teams", {});

      this.isLoading = false;

      this.teams = task_teams;
      
    },
    onInput(value) {
      this.$emit("input", value);
    }
  }
};
</script>
