<template>
  <div class="card task-card" v-bind:class="{ dashboard: isDashboard }" v-loading="isLoading">
    <div class="card-header">
      <!-- Title -->
      <div class="d-md-flex justify-content-between">
        <div class="">
          <router-link :to="`/project/${this.$route.params.id}/tasks`" v-if="project_id">
            <h5 class="h3 mb-0">{{ $t('message.tasksHeader') }} <small>{{
              $t('message.tasksHeaderDone', { taskAmount:
                tasks.length, done: finishedTasks
            }) }}</small></h5>
          </router-link>
          <h5 class="h3 mb-0" v-else>{{ $t('message.tasksHeader', { taskAmount: tasks.length, done: finishedTasks }) }}
            <small>{{ $t('message.tasksHeaderDone', { taskAmount: tasks.length, done: finishedTasks }) }}</small>
          </h5>
        </div>
        <div class="d-flex">
          <div class="mt-1">
            <base-button size="sm" type="primary" v-if="showCardToggle" @click="toggleCardView">
              {{ $t('task.card_view') }}
            </base-button>
            <base-button size="sm" type="primary" @click="doExport()">
              {{ $t('message.export') }}
            </base-button>
            <el-tooltip :content="$t('message.new')" placement="top" v-if="isDashboard">
              <base-button type="primary" icon round size="sm" @click="onNew" style="height: 30px;"
                :disabled="project && project.state === 'ARCHIVED'">
                <i class="fas fa-plus p-0"></i>
              </base-button>
            </el-tooltip>
          </div>
        </div>
      </div>

    </div>
    <div class="row ml-2 mr-3 filterSelect" :class="{ 'justify-content-center': showCardView }">
      <div class="col-md-12 col-lg-4 mt-2 pr-0" v-if="!isPersonal">
        <div class="pr-2 task-label">{{ $t('message.user') }}:</div>
        <apa-user-select :nullable="$t('data_input.all_users_label')" v-model="filter.user_id" @change="resetUser('team')" />
      </div>
      <div class="col-md-12 col-lg-4 mt-2 pr-0" v-if="!isPersonal">
        <div class="pr-2 task-label">{{ $t('message.teams') }}:</div>
        <apa-team-select :nullable="$t('task.no_team')" v-model="filter.team_users" @change="resetUser('user')" />
      </div>
      <div class="col-md-12 col-lg-4 mt-2 pr-0" v-if="!project_id">
        <div class="pr-2 task-label">{{ $t('message.project') }}:</div>
        <apa-project-select :nullable="$t('tasks.no-project')" v-model="filter.project_id"
          :valid-projects="validProjects" />
      </div>
      <div class="col-md-12 col-lg-4 mt-2 pr-0">
        <div class="pr-2 task-label">{{ $t('data_input.creators_label') }}:</div>
        <apa-task-creator-select :nullable="$t('data_input.all_creators_label')" :project="project_id"
          v-model="filter.created_by" />
      </div>
      <div class="col-md-12 col-lg-4 mt-2 pr-0 v" v-if="filterProjectId && !showCardView">
        <div class="pr-2 task-label">{{ $t('message.milestone') }}:</div>
        <apa-milestone-select :nullable="$t('data_input.all_milestones_label')"
          :filter="{ project_id: filterProjectId }" v-model="filter.milestone_id" />
      </div>
    </div>
    <div class="row ml-2 mr-3 filterSelect" :class="{ 'justify-content-center': showCardView }">
      <div class="col-lg-8 col-md-12 mt-2 pr-0">
        <base-input v-model="searchValue" :placeholder="$t('global.search')" />
      </div>
    </div>
    <div class="d-md-flex flex-row ml-4 mb-5 header-checkboxes" :class="{ 'justify-content-center': showCardView }">
      <div>
        <base-checkbox v-model="showDoneTasks" class="checkbox mr-6">{{ $t('tasks.show-done-tasks') }}</base-checkbox>
      </div>
      <div v-if="!showCardView">
        <base-checkbox v-model="tasksGrouped" class="mr-6">{{ $t('task.grouped-by-user') }}</base-checkbox>
      </div>
      <div>
        <base-checkbox v-model="tasksOverdue" class="mr-6">{{ $t('task.overdue') }}</base-checkbox>
      </div>
      <div>
        <base-checkbox v-model="tasksDueToday">{{ $t('task.due_today') }}</base-checkbox>
      </div>
    </div>


    <div class="card-body pt-0">
      <h3 v-if="!showCardView">{{ $t('task.favorites') }}</h3>
      <ul class="list-group list-group-flush flex-fill" :class="repeatingTasks.length > 0 ? '' : 'mb-5'"
        data-toggle="checklist" v-if="favTasks.length > 0 && !showCardView">
        <task-item v-for="task in favTasks.filter(t => t.state !== 'CLOSED')" :fixed-project-id="project_id"
          :key="task.id" :showTools="true" :task="task" @newDate="onDateUpdate" :show-done-tasks="showDoneTasks"
          :is-fav="true" @command="onCommand" />
      </ul>

      <ul class="list-group list-group-flush flex-fill mb-5" 
        data-toggle="checklist" v-if="repeatingTasks.length > 0 && !showCardView">
        <task-item v-for="task in repeatingTasks" :fixed-project-id="project_id" :key="task.id" :showTools="true"
          :task="task" @newDate="onDateUpdate" @command="onCommand" />
      </ul>

      <h3>{{ $t('task.all_tasks') }}</h3>
      <ul class="list-group list-group-flush flex-fill mb-5" data-toggle="checklist"
        v-if="tasks.length > 0 && !showCardView">
        <el-collapse v-model="activeElements" v-if="tasksGrouped">
          <el-collapse-item v-for="eventGroup in Object.keys(mainTasks)" :name="eventGroup" :key="eventGroup">
            <template slot="title">
              <div class="d-flex flex-row align-items-center">
                <div class="checklist-avatar checklist-info mr-2 mt-3">
                  <avatar :user-id="mainTasks[eventGroup][0].user.id"></avatar>
                </div>
                <div>
                  {{ `${mainTasks[eventGroup][0].user.name} (${mainTasks[eventGroup].length})` }}
                </div>
              </div>

            </template>
            <task-item v-for="task in mainTasks[eventGroup]" v-if="!task.interval" :fixed-project-id="project_id"
              :key="task.id" :showTools="true" :task="task" :show-done-tasks="showDoneTasks" :show-avatar="false"
              :is-fav="task.favTaskRef" @newDate="onDateUpdate" @command="onCommand" />
          </el-collapse-item>
        </el-collapse>
        <task-item v-else v-for="task in mainTasks" :fixed-project-id="project_id" :key="task.id" :showTools="true"
          :task="task" :show-done-tasks="showDoneTasks" @newDate="onDateUpdate" :is-fav="task.favTaskRef"
          @command="onCommand" @toggle-fav="onToggleFav" />
      </ul>
      <div class="row mr-3 filterSelect" v-if="tasks.length > 0 && showCardView">
        <div class="col mt-2 pr-0">
          <h3>{{ $t('task.no_milestones') }}</h3>
          <div class="card-col">
            <div class="card task-card " v-for="task in mainTasks.filter((mt) => mt.milestone === null)">
              <task-item :fixed-project-id="project_id" :is-card="true" :key="task.id" :showTools="true" :task="task"
                :show-done-tasks="showDoneTasks" @newDate="onDateUpdate" :is-fav="task.favTaskRef" @command="onCommand"
                @toggle-fav="onToggleFav" />
            </div>
          </div>

        </div>
        <div class="col mt-2 pr-0 " v-for="ms in milestones">
          <h3>{{ fullMilestones.find((fm) => fm.id === ms).name }} | Fällig: {{
            fullMilestones.find((fm) => fm.id ===
              ms).due | date
          }}</h3>
          <div class="card-col">
            <div class="card task-card" v-for="task in mainTasks.filter((mt) => mt.milestone_id === ms)">
              <task-item :fixed-project-id="project_id" :is-card="true" :key="task.id" :showTools="true" :task="task"
                :show-done-tasks="showDoneTasks" @newDate="onDateUpdate" :is-fav="task.favTaskRef" @command="onCommand"
                @toggle-fav="onToggleFav" />
            </div>
          </div>
        </div>

      </div>


      <div v-if="tasks.length === 0">{{ $t('message.noTasks') }}</div>
      <task-form :fixed-project-id="project_id" :show.sync="showForm" :task="task" :valid-projects="validProjects"
        v-if="task" @changed="onChanged" @command="onCommand" @refresh="onRefresh" />
    </div>
    <modal :show.sync="isCopyModalVisible">
      <h6 slot="header" class="modal-title">{{ $t('task.copy-from-project') }}</h6>
      <el-select v-model="projectToCopyFrom" filterable @input="showTaskList">
        <el-option v-for="project in allProjects" :key="project.id" :label="project.name" :value="project.id" />
      </el-select>
      <div class="mt-4" v-if="projectTasks.length > 0">
        <el-table ref="inputTable" class="table-responsive table-flush position-table" row-class-name="no-hover"
          header-row-class-name="thead-light" :data="projectTasks" row-key="id"
          @selection-change="handleSelectionChange" stripe style="width: 100%;">
          <el-table-column type="selection" width="40"></el-table-column>
          <el-table-column prop="title" :label="$t('message.tasks')"></el-table-column>
        </el-table>
      </div>
      <template slot="footer">
        <base-button type="primary" @click="onStartCopy" :disabled="selectedIds.length === 0">{{
          $t('message.copy')
        }}</base-button>
        <base-button type="link" class="ml-auto" @click="isCopyModalVisible = false">{{ $t('message.cancel') }}
        </base-button>
      </template>
    </modal>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapActions } from "vuex";
import ApaTaskCreatorSelect from "@/components/ApaInputs/ApaTaskCreatorSelect";
import ApaMilestoneSelect from "@/components/ApaInputs/ApaMilestoneSelect";
import ApaProjectSelect from "@/components/ApaInputs/ApaProjectSelect";
import ApaTeamSelect from "@/components/ApaInputs/ApaTeamSelect";
import ApaUserSelect from "@/components/ApaInputs/ApaUserSelect";
import createTask from "@/store/modules/tasks/createTask";
import pickTask from "@/store/modules/tasks/pickTask";
import TaskForm from "./TaskForm";
import TaskItem from "./TaskItem";
import { TASK_CLOSED, TASK_NEW } from "@/store/modules/tasks/constants";
import groupBy from '@/util/groupBy'
import download from '@/util/download'
import { debounce } from 'lodash'


export default {
  name: "task-list",
  components: {
    ApaTeamSelect,
    ApaUserSelect,
    ApaProjectSelect,
    ApaMilestoneSelect,
    ApaTaskCreatorSelect,
    TaskForm,
    TaskItem
  },
  props: {
    isPersonal: {
      type: Boolean,
      default: false
    },
    isDashboard: {
      type: Boolean,
      default: false
    },
    showCardToggle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const { params } = this.$route;
    return {
      filter: {
        id: null,
        milestone_id: null,
        project_id: -1,
        user_id: null,
        created_by: null,
        team_users: null
      },
      selectedTextHandled: false,
      searchValue: '',
      isLoading: false,
      project_id: "id" in params ? parseInt(params.id) : null,
      showForm: false,
      task: null,
      tasks: [],
      showDoneTasks: false,
      showCardView: false,
      parentStyle: '',
      oldParentStyle: '',
      selectedText: '',
      tasksGrouped: true,
      tasksOverdue: false,
      tasksDueToday: false,
      activeElements: [],
      isCopyModalVisible: false,
      projectToCopyFrom: null,
      projectTasks: [],
      selectedIds: [],
    };
  },
  watch: {
    $route({ params }) {
      this.project_id = "id" in params ? parseInt(params.id) : null;
    },
    filterParams: {
      immediate: true,
      handler: debounce(function () {
        this.load();
      }, 200)
    },
    async tasks(_, old) {
     

      if (this.searchCtx.task_id) {
        const task = this.tasks.find(t => t.id == this.searchCtx.task_id);
        if (task) {
          await this.onEdit(task)
        }
      }

      if (this.$route.params.taskId && old.length === 0) {
        this.onCommand('edit', this.tasks.find(t => t.id === parseInt(this.$route.params.taskId)))
      }
    },
    showForm(val) {
      if (val == false) {
        this.parentStyle.cssText = this.oldParentStyle
      }
    },
    'filter.user_id'() {
      if (this.filter.team_users) {
        this.filter.team_users = null
      }
    }
  },
  computed: {
    ...mapGetters([
      'project',
      'currentUser',
      'searchCtx',
      'userTasks',
      'allProjects',
      'userProjects',
      'currentUserRights'
    ]),
    filterParams() {
      
      const filter = {}
      const project_id = this.filterProjectId
      let { created_by, milestone_id, user_id, id, team_users } = this.filter

      if (id) {
        filter.id = id
      }
      if (milestone_id) {
        filter.milestone_id = milestone_id
      }
      filter.project_id = project_id

      if (filter.project_id === -1) {
        delete filter.project_id
      }
      if (created_by) {
        filter.created_by = created_by
      }
      if (team_users) {
        delete filter.user_id
        filter.user_id = {'in': team_users.split(',').map(t => parseInt(t))}
      }
      if (!this.showDoneTasks) {
        filter.state = TASK_NEW;
      }
      else{
        delete filter.state
      }
      if (user_id && !team_users) {
        delete filter.team_users
        this.filter.team_users = null
        filter.user_id = user_id
      }

      return filter;
    },
    filterProjectId() {
      return this.project_id ? this.project_id : this.filter.project_id;
    },
    finishedTasks() {
      return this.tasks.filter(t => t.state === TASK_CLOSED).length
    },
    repeatingTasks() {
      const self = this;
      return this.tasks
        .filter(t => t.interval !== null)
        .filter(t => t.state !== 'CLOSED')
        .filter(task => {
          if (task.project && task.project.is_private) {
            if (!task.project.projectPersons) {
              return false
            }

            if (!self.currentUserRights.includes('project.can-see.private')) {
              return false
            }

            return task.project.projectPersons.map(pp => pp.user_id).includes(self.currentUser.id)
          }
          return true
        })
    },
    favTasks() {
      const self = this
      return this.tasks
        .filter(t => t.favTaskRef !== undefined)
        .filter(task => {
          if (task.project && task.project.is_private) {
            if (!task.project.projectPersons) {
              return false
            }

            if (!self.currentUserRights.includes('project.can-see.private')) {
              return false
            }

            return task.project.projectPersons.map(pp => pp.user_id).includes(self.currentUser.id)
          }
          return true
        })
    },
    mainTasks() {
      const self = this
      let tasks = this.tasks.filter(task => {
        if (task.project && task.project.is_private) {
          if (!task.project.projectPersons) {
            return false
          }

          if (!self.currentUserRights.includes('project.can-see.private')) {
            return false
          }

          return task.project.projectPersons.map(pp => pp.user_id).includes(self.currentUser.id)
        }
        return true
      })

      tasks = tasks.filter(t => t.interval === null)

      tasks = tasks.filter(t =>
        !this.searchValue
        || !!t.children.filter(ct => ct.title.toLowerCase().includes(this.searchValue.toLowerCase())).length
        || t.title.toLowerCase().includes(this.searchValue.toLowerCase())
      )

      // Tasks ausblenden, die von Benutzern erstellt wurden, die von der Hierachie her übergeordnet sind.
      tasks = tasks.filter(t => {
        if (this.currentUserRights.includes('tasks.parents.canseeownonly')) {
          return t.user.role.parent === this.currentUser.role.parent
        } else {
          return t.user.role.parent >= this.currentUser.role.parent || t.project_id !== null
        }
      })
      if (!this.showDoneTasks) {
        tasks = tasks.filter(t => t.state === TASK_NEW)
      }

      if (this.tasksOverdue) {
        tasks = tasks.filter(t => t.due && new Date(t.due) <= new Date())
      }

      if (this.tasksDueToday) {
        tasks = tasks.filter(t => t.due && this.isToday(new Date(t.due)))
      }

      if (this.tasksGrouped) {
        tasks = groupBy(tasks, 'user_id')
      }

      return tasks
    },
    milestones() {
      return [...new Set(this.tasks.filter((t) => t.milestone !== null).map((t) => t.milestone_id).sort((m1, m2) => m1 - m2))];
    },
    fullMilestones() {
      return [...new Set(this.tasks.filter((t) => t.milestone !== null).map((t) => {
        return {
          id: t.milestone_id,
          name: t.milestone.name,
          due: t.milestone.due
        }
      }).sort((m1, m2) => m1.id - m2.id))]
    },
    validProjects() {
      let projects
      if (this.currentUserRights.includes('Archiv_Projekte wiederherstellen')) {
        projects = this.allProjects
      } else {
        projects = this.userProjects
      }

      projects = projects.filter(project => {
        if (project.is_private) {
          return this.currentUserRights.includes('project.can-see.private')
        }
        return true
      })

      return projects
        .map(up => up.id)
    }
  },
  mounted() {
    // set filters
    if (!this.$route.params.id) {
      this.filter.user_id = this.currentUser.id
    }

  },
  methods: {
    ...mapActions([
      'getMilestonesForProject'
    ]),
    onCopyClick() {
      this.isCopyModalVisible = true;
      this.projectTasks = [];
      this.selectedIds = [];
    },
    isToday(date) {
      const today = new Date()
      return date.getDate() == today.getDate() &&
        date.getMonth() == today.getMonth() &&
        date.getFullYear() == today.getFullYear();
    },
    handleSelectionChange(val) {
      this.selectedIds = val.map((p) => p.id);
    },
    async showTaskList(value) {
      this.projectTasks = await this.$store.dispatch("loadTasks", {
        filter: { 'project_id': value }
      })

      this.projectTasks = projectTasks.sort(function (a, b) {
        var c = a.due !== null ? new Date(a.due) : 0
        var d = b.due !== null ? new Date(b.due) : 0
        return c - d
      })
    },
    async onStartCopy() {
      try {
        const { id } = this.$route.params
        if (this.projectTasks.length > this.selectedIds.length) {
          // copy some
          await Vue.http.get(`tasks/${id}/copy-single/${this.selectedIds}`);
        } else {
          // copy all
          await Vue.http.get(`tasks/${id}/copy/${this.projectToCopyFrom}`);
        }
        this.isCopyModalVisible = false
        this.load()
      } catch (error) {
        console.log(error)
      }
    },
    toggleCardView() {
      this.filter.milestone_id = null;
      this.tasksGrouped = false;
      this.showCardView = !this.showCardView
    },
    load: async function () {
      const self = this
      this.isLoading = true

      if (typeof this.$route.params.selectedText !== "undefined" && this.selectedTextHandled === false) {
        this.selectedText = this.$route.params.selectedText;
        this.onNew();
      }
      const tasks = await this.$store.dispatch("loadTaskHierarchy", {
        filter: this.filterParams,
      })

      this.tasks = tasks.sort(function (a, b) {
        var c = a.due !== null ? new Date(a.due) : 0
        var d = b.due !== null ? new Date(b.due) : 0
        return c - d
      })

      let favTaskRefs = await this.$store.dispatch("loadFavTasks", {
        user_id: this.currentUser.id
      })

      this.tasks = this.tasks
        .map(t => {
          t.favTaskRef = favTaskRefs.find(tr => tr.task_id === t.id)
          return t
        })

      this.isLoading = false;
    },
    async onNew() {
      const { project_id } = this;

      const data = createTask({
        title: '',
        project_id,
        user_id: this.currentUser.id,
        created_by: this.currentUser.id
      })

      if (this.selectedText !== '') {
        data.title = this.selectedText;
        this.selectedTextHandled = true
      }

      //const { id } = await this.$store.dispatch("createTask", { data });

      await this.load()
      //const task = this.tasks.find(t => t.id === id)
      await this.onEdit(data)
    },
    onAdd() {
      this.modifyParentGridElementStyle()
      const { project_id } = this;
      this.task = createTask({ project_id });

      if (this.selectedText !== '') {
        this.task.title = this.selectedText;
      }

      this.showForm = true;
    },
    onAddChild(parent) {
      const { project_id, milestone_id, due } = this;
      // delete parent.project_id
      this.task = createTask({ parent, milestone_id: parent.milestone_id, user_id: parent.user_id });
      this.showForm = true;
    },
    modifyParentGridElementStyle() {
      if (this.isDashboard) {
        this.oldParentStyle = JSON.parse(JSON.stringify(this.$parent.$parent.$refs.item.style.cssText))
        this.parentStyle = this.$parent.$parent.$refs.item.style
        const elements = this.oldParentStyle.split(';')

        const coordinates = elements[0]
          .substr(23)
          .slice(0, -1)
          .split(',')
          .map(i => i.trim())

        // transform entfernen
        elements.splice(0, 1)

        elements.push(`left: ${coordinates[0]}`)
        elements.push(`top: ${coordinates[1]}`)
        const newCSS = elements.join(';')
        this.parentStyle.cssText = newCSS
      }
    },
    onChanged() {
      this.load()
    },
    onRefresh(id) {
      const self = this
      this.$store.dispatch('loadTasks', { filter: { id } })
        .then(tasks => {
          if (tasks.length > 0) {
            const task = tasks[0]
            const index = self.tasks.findIndex(i => i.id === task.id)
            this.$set(self.tasks, index, task);
          }
        })
    },
    async onCommand(command, task, options) {
      if (this.$route.params.id) {
        this.getMilestonesForProject(this.$route.params.id)
      }
      switch (command) {
        case "addChild":
          return this.onAddChild(task);
        case "edit":
          return await this.onEdit(task);
        case "copy":
          return this.onCopy(task);
        case "delete":
          return this.onDelete(task);
        case "state":
          return this.onState(task, options);
        case "add-fav":
          return this.onAddFav(task);
        case "remove-fav":
          return this.onRemoveFav(task);
        case "navigate":
          return this.onSubNavigate(task);
      }
    },
    onDelete: async function (task) {
      this.isLoading = true;
      if (task.favTaskRef) {
        await this.$store.dispatch("deleteTask_fav", {
          id: task.favTaskRef.id
        })
      }

      const notifications = await this.$store.dispatch("findNotifications", {
        data: {
          and: {
            task_id: task.id
          }
        }
      })

      if (notifications.length > 0) {
        await this.$store.dispatch("deleteNotification", { id: notifications.map(n => n.id) });
      }

      await this.$store.dispatch("deleteTaskRecursive", { id: task.id });
      await this.load();
    },
    async onEdit(task) {
      if (task.id)
        this.task = (await this.$store.dispatch('findTasks', {
          data: {
            and: {
              id: task.id
            },

            with: {
              subtasks: {
                many: 'subtask',
                this: 'id',
                that: 'task_id'
              },
              childTaskRefs: {
                many: 'task_rel',
                this: 'id',
                that: 'from_id',
                query: {
                  with: {
                    task: {
                      'one': 'task',
                      'this': 'to_id',
                      'that': 'id',
                      query: {
                        with: {
                          user: {
                            one: 'users',
                            this: 'user_id',
                            that: 'id',
                          }
                        }
                      }
                    }
                  }
                }
              },
              comments: {
                many: 'comment',
                this: 'id',
                that: 'task_id',
                query: {
                  with: {
                    documents: {
                      many: 'document',
                      this: 'id',
                      that: 'item_id',
                      query: {
                        and: {
                          type: 'comment_attachment'
                        }
                      }
                    }
                  }
                }
              }
            }
          },
        }))[0];
      else
        this.task = { ...task };
      this.showForm = true;
    },
    onSubNavigate(task) {
      this.task = null;
      setTimeout(() => {
        this.task = { ...task };
      }, 100)
    },
    async onCopy(task) {
      this.isLoading = true;
      let newTask = createTask({ ...task });

      const { id } = await this.$store.dispatch("createTask", { data: newTask });
      const subtasks = await this.$store.dispatch('findSubtasks', {
        data: {
          and: {
            task_id: task.id
          }
        }
      })

      await Promise.all(subtasks.map(st => {
        delete st.id
        st.task_id = id
        return this.$store.dispatch('createSubtask', { data: st })
      }))

      await this.load()
      newTask = this.tasks.find(t => t.id === id)
      await this.onEdit(newTask)
    },
    onState(task, { state }) {
      this.$store.dispatch("updateTask", {
        id: task.id,
        data: {
          ...pickTask(task),
          state
        }
      });
    },
    doExport() {
      const params = {
        se: this.searchValue,
        ms: this.filter.milestone_id,
        pr: this.project_id || this.filter.project_id,
        us: this.filter.user_id,
        dt: this.showDoneTasks,
        gr: this.tasksGrouped,
        du: this.tasksOverdue,
      }

      const values = []
      Object.keys(params).forEach(key => {
        const value = params[key]
        if (value && value != -1) {
          values.push(`${key}=${value}`)
        }
      })
      const q = values.join('&')
      const url = `pdf/tasks?${q}`

      download(url, "Aufgaben.pdf")
    },
    async onAddFav(task) {
      await this.$store.dispatch("createTask_fav", {
        data: {
          task_id: task.id,
          user_id: this.currentUser.id
        }
      })
      this.load()
    },
    async onRemoveFav(task) {
      await this.$store.dispatch("deleteTask_fav", {
        id: task.favTaskRef.id
      })
      this.load()
    },
    async onDateUpdate(newDate, task) {
      await this.$store.dispatch("updateTask", {
        id: task.id,
        data: {
          id: task.id,
          due: newDate
        }
      });
    }
  }
};
</script>

<style>
.dashboard .header-checkboxes .custom-checkbox {
  margin-right: 1.2em !important;
}

.task-card .el-select {
  width: 100%;
}

.el-dropdown {
  margin-top: 5px;
  cursor: pointer;
}

.checklist-item .custom-control.custom-checkbox {
  margin-top: 3px;
}

.task-card .el-select .el-input .el-input__inner,
.task-card input.form-control {
  max-height: 35px;
  padding: 0 8px;
}

.task-card .task-label,
.task-card .custom-checkbox .custom-control-input~.custom-control-label {
  line-height: 26px;
  font-size: 13px;
}

input.taskdate.form-control.input {
  background-color: transparent;
  border: none;
  box-shadow: none;
  font-size: 11px;
  line-height: 1.7;
  padding: 0;
  cursor: pointer;
  height: auto;
  width: 65px;
  text-align: right;
  float: right;
}

.list-group-item {
  padding: 0.5rem;
}

.card-col {
  height: 550px;
  overflow-y: auto;
}

.task-preview p {
  font-size: 13px;
  margin-bottom: 2px;
}

/* .task-card .custom-control {
      padding: 2px 0px;
  } */

@media (max-width: 767px) {
  .filterSelect {
    display: block;
  }
}

</style>
